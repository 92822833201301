import classNames from 'classnames';
import styles from './TileCard.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { Picture } from 'ui/components/1-atoms/Media/Picture/Picture';
import { Kicker, KickerProps } from 'ui/components/1-atoms/Kicker/Kicker';
import { LinkType } from 'helpers/types';
import { Player } from 'ui/components/1-atoms/Media/Player/Player';
import { useInView } from 'react-intersection-observer';

export interface TileCardProps {
	className?: string;
	image?: {
		url: string;
		altText: string;
		focalPoint?: { left: number; top: number };
	};
	video?: {
		url: string;
		poster?: string;
		autoplay?: boolean;
	};
	link?: LinkType;
	kicker?: KickerProps;
	heading?: string;
	text?: string;
	imageCropping?: 'cover' | 'contain';
	borderNone?: boolean;
	noPaddingTop?: boolean;
}

export const TileCard: React.FC<TileCardProps> = ({
	className,
	image,
	video,
	link,
	kicker,
	heading,
	text,
	imageCropping = 'cover',
	borderNone,
	noPaddingTop,
}) => {
	const { ref, inView } = useInView({
		threshold: 0.8,
		triggerOnce: false,
	});
	return (
		<div
			ref={ref}
			className={classNames(
				styles.TileCard,
				{ [styles[`TileCard___noPaddingTop`]]: noPaddingTop },
				{ [styles.TileCard___borderNone]: borderNone === true },
				className,
			)}
		>
			<div className={styles.TileCard_textbox}>
				{kicker && <Kicker className={styles.TileCard_kicker} {...kicker}></Kicker>}
				{heading && (
					<Heading className={styles.TileCard_heading} headingLevel="h2">
						{heading}
					</Heading>
				)}
				{text && <div className={styles.TileCard_text} dangerouslySetInnerHTML={{ __html: text }}></div>}
				{link && (
					<div>
						<Link href={link.url} target={link.target} className={styles.TileCard_link} underline={true}>
							{link.name}
						</Link>
					</div>
				)}
			</div>
			{image && image.url && (
				<div className={styles.TileCard_media}>
					<span className={styles.TileCard_mediabox}>
						{video && video.url ? (
							<Player
								cover
								url={video.url}
								isLight={(video.autoplay && !inView) || !video.autoplay ? true : false}
								playing={inView ? true : false}
								muted={true}
								loop={true}
								controls={false}
								poster={image.url}
								playIcon={
									!video.autoplay ? (
										inView ? (
											<div className={styles.TileCard_playIconContainer}>
												<span className={styles.TileCard_playIcon}></span>
											</div>
										) : (
											<span></span>
										)
									) : (
										<span></span>
									)
								}
							/>
						) : (
							image &&
							image.url && (
								<Picture
									aspectRatio={0.5625}
									url={image.url}
									altText={image.altText}
									focalPoint={image.focalPoint}
									sizes="(max-width: 768px) 100vw, 460px"
									imageCropping={imageCropping}
								/>
							)
						)}
					</span>
				</div>
			)}
		</div>
	);
};
